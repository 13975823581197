import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Hero from "../components/plataforma/Hero"
import Plataforma from "../components/plataforma/Plataforma"
import Formulario from "../components/plataforma/Formulario"

const IndexPage = (props) => (
  <Layout>
    <Seo title="Plaforma"/>
    <Hero/>
    <Plataforma/>
    <Formulario/>

  </Layout>
);

export default IndexPage;

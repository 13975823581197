import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
const Plataforma = () => {
    return (
    <section id = "plataforma">
        <div className = "plataformaBlock">
            <h1>Nuestra plataforma</h1>
            <div className = "row">
                <div className = "column" id ="Bullets">
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Código abierto</strong>
                            <p>No requiere pago de licencias adicionales en el futuro.</p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Aplicación web multiplataforma</strong>
                            <p>Se puede adaptar a cualquier sistema operativo de computadoras o celulares.</p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Servicios de TeleSalud, referencia y respuesta</strong>
                            <p>Permite la realización de teleconsultas sincrónicas y asincrónicas, con intercambio de polimedia</p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Seguridad e interoperabilidad</strong>
                            <p>Cumple con estándares internaciones de seguridad e intercambio de 
                                información en salud.
                            </p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Expediente clínico</strong>
                            <p>Se brinda seguimiento continuo sobre el estado de salud del paciente.
                            </p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Programación de citas</strong>
                            <p> Tiene la capacidad de programar citas a futuro para brindar mejor seguimiento.
                            </p>
                        </li>
                        <li>
                             <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Estadística y reportería</strong>
                            <p>
                                Tiene el modúlo que captura los datos que serán utilizados para futuras investigaciones.
                            </p>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <strong>Escalable y configurable</strong>
                            <p>
                                La plataforma se encuentra en constante actualización y crecimiento. Se acopla a las necesidades
                                de la población
                            </p>
                        </li>
                    </ul>
                </div>
                <div className = "columns">
                    <StaticImage
                        src = "../../assets/plataforma.png"
                        width = {500}
                        alt= "Telesan plataforma"
                    />
                 </div>
            </div>
        </div>

    </section>
    )
}

export default Plataforma
import React from "react"


class Formulario extends React.Component {

  render() {
    return (
      <section id = "telesan-hero2">
      <div className = "telesan-row">
              <div className="column">
                      <p> ¿Quieres ser parte de nuestro equipo de voluntarios?</p> 
                     

                      <form action = "/gracias" name="Contact Form" method="POST" data-netlify="true">
                        <input type="hidden" name="form-name" value="Contact Form" />
                        <div>
                          <label>Nombre completo:</label>
                          <input type="text" name="name" />
                        </div>
                        <div>
                          <label>Número de teléfono:</label>
                          <input type="text" name="phone" />
                        </div>
                        <div>
                          <label>Correo electrónico:</label>
                          <input type="email" name="email" />
                        </div>
                        <div>
                          <label>Area de trabajo/especialización:</label>
                          <input type="text" name="name" />
                        </div>
                        <div>
                          <label>Pequeña descripción sobre lo que haces:</label>
                          <textarea name="message" />
                        </div>
                        <button type="submit">Enviar</button>
                    </form>
              </div>
             
      </div>
  </section>
    )
  }
}

export default Formulario;
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
    return (
    <section id = "telesan-hero1">
        <div className = "content-telesan">
            <div className= "row">
                    <StaticImage
                        src = "../../assets/telesan/telesan_desktop.png"
                        width = {1800}
                        alt= "Telesan plataforma telesalud"
                    />
            </div>
        </div>
    </section>
    )
}

export default Hero